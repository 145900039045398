import { render, staticRenderFns } from "./launcher.vue?vue&type=template&id=477ded50&lang=pug&"
import script from "./launcher.vue?vue&type=script&lang=coffee&"
export * from "./launcher.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VDialog})
