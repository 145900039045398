
import EventBus from '@/shared/services/event_bus'
import AppConfig from '@/shared/services/app_config'
import Records from '@/shared/services/records'
import Flash  from '@/shared/services/flash'

export default
  props:
    close: Function
    group: Object

  data: ->
    chatbot: null
    kinds: AppConfig.webhookEventKinds
    testing: false

  mounted: ->
    Records.chatbots.fetch(params: {group_id: @group.id}).then =>
      @chatbot = Records.chatbots.find(groupId: @group.id)[0] ||
                 Records.chatbots.build(groupId: @group.id)

  methods:
    submit: ->
      @chatbot.save()
      .then =>
        Flash.success 'chatbot.saved'
        @close()
      .catch (b) =>
        console.log @chatbot.errors

    testConnection: ->
      @testing = true
      Records.remote.post('chatbots/test',
        server: @chatbot.server
        access_token: @chatbot.accessToken
        channel: @chatbot.channel
        group_name: @group.name
      ).then =>
        Flash.success('chatbot.test_connection_ok')
      , =>
        Flash.error('chatbot.test_connection_bad')
      .finally =>
        @testing = false

